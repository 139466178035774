<template>
  <b-row>
    <b-col>
      <b-form-group
        class="accordion-materias"
        label="Destinatários"
        style="z-index: 1"
      >
        <div
          v-if="
            data.targets.length > 0 && data.targets[0].Resource != 'Alunos'
          "
          class="accordion"
          role="tablist"
        >
          <b-card no-body v-for="(target, index) in data.targets" :key="index">
            <b-card-header header-tag="header" role="tab">
              <p block v-b-toggle="'accordion-' + index">
                {{ target.Resource }}
              </p>
            </b-card-header>

            <b-collapse
              :id="'accordion-' + index"
              visible
              accordion="my-accordion"
              role="tabpanel"
            >
              <b-card-body>
                <div class="wrap-tags">
                  <span v-for="(value, index) in target.values" :key="index">
                    {{ value.description }}
                  </span>
                </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>

        <table
          v-if="
            data.targets.length > 0 && data.targets[0].Resource == 'Alunos'
          "
          class="table-students mt-5"
          aria-describedby="Alunos"
        >
          <tr>
            <th id="alunos">
              <h4 class="purple">Alunos</h4>
            </th>
            <th id="data">
              <h4 class="purple">Responsáveis</h4>
            </th>
          </tr>

          <tr
            v-for="(targetValue, index) in data.targets[0].values"
            :key="index"
          >
            <td>
              <div class="wrap-tags">
                <span>{{ targetValue.description }}</span>
              </div>
            </td>
            <td>
              <div class="student-responsible">
                <span
                  v-for="(subResourceValue, indexSub) in targetValue.subValues"
                  :key="indexSub"
                  >{{ subResourceValue.description }}
                </span>
              </div>
            </td>
          </tr>
        </table>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "Targets",

  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss">
.teacher-profile {
  .form-group {
    &.required {
      &:after {
        right: 0;
      }
    }
  }
}

.table-students {
  width: 100%;

  td,
  th {
    font-size: 18px;
  }

  th {
    padding-bottom: 20px;
  }

  td {
    padding: 20px 0;
  }

  tr td:last-of-type {
    text-align: right;

    .actions {
      span {
        cursor: pointer;
      }

      .deleteStudent {
        margin-left: 30px;
      }
    }
  }

  tr + tr {
    border-bottom: 1px solid $grey5;
  }
}

.title-collapse {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  h5 {
    flex: 1;
  }

  .btn {
    background: transparent;
    border: 0;

    svg {
      font-size: 16px;
      fill: red;
    }
  }
}

.wrap-tags {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;

  span {
    background-color: #efefef;
    padding: 4px 8px;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

.wrap-button {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px;
}

.tag {
  background-color: #68c5b7;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  padding: 0 3px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
  vertical-align: middle;
  max-width: 100%;
}

.container-rda .multiselect__tag-icon:after {
  color: white;
}

input.isInvalid {
  border-color: red;
}
</style>
